@font-face {
  font-family: GothamRoundedLight;
  src: url('../assets/GothamRounded-Light.otf') format('opentype');
}

body {
  margin: 0;
  font-family: GothamRoundedLight;
  /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.flag-select img {
  float: left;
}

@font-face {
  font-family: GothamRoundedBold;
  src: url('../assets/Gotham Rounded Bold.otf') format('opentype');
}

button {
  font-family: GothamRoundedBold;
}

.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

button {
  outline: none !important;
}

button:focus {
  outline: none !important;
}

video {
  outline: none;
}

.w-fit {
  width: fit-content;
}

.transition-opacity {
  transition: opacity 0.25s ease;
}

.transition-opacity-slow {
  transition: opacity 0.5s ease;
}

.video-dialog {
  overflow: hidden;
}

.video-thumbnail {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  transform: scale(1);
  transition: background-color 0.25s ease, transform 0.25s ease;
}

.video-thumbnail:hover {
  background-color: rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
}
input {
  outline: none !important;
}
